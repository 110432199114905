import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { BASE_URL, BASE_URL_ANALIZ, BASE_URL_NUSKA, BASE_URL_OFFICE } from "../../../../utils/constants/base_url";
import { removeTestAnswerItems } from "../../test_answer/testAnswer.slice";
import { RootState } from "../../../store";
import { ITestWork, ITestWork5 } from "../../test_work/testwork.interface";
import { stopTimer } from "../../timer.slice";
import { setWorkData, setWorkData5, setWorkDataNuska, setWorkDataTeacher } from "../../test_work/testwork.slice";
import { removeTestData, setConfirmm } from "../test.slice";

const finishTestAction = (dispatch: any) => {
   dispatch(stopTimer());
   dispatch(removeTestAnswerItems());
   dispatch(setConfirmm(false));
   dispatch(removeTestData());
};

export const finishTestTeacher = createAsyncThunk<void, void, { state: RootState }>(
   "test/finish",
   async (_, { getState, dispatch }) => {
      try {
         const { data } = await axios.post<ITestWork>(`${BASE_URL}/api/kval/end`, {
            Id: getState().test.dataTeacher?.userTestId,
            UserId: getState().auth.data?.userId,
            Items: getState().testAnswer.dataTeacher,
         });
         if (!data) throw new Error("Result is null");
         dispatch(setWorkDataTeacher(data));
         finishTestAction(dispatch);
      } catch (e: any) {
         throw new Error(e.message);
      }
   }
);

export const finishTest = createAsyncThunk<void, void, { state: RootState }>(
   "test/finish",
   async (_, { getState, dispatch }) => {
      try {
         const { data } = await axios.post<ITestWork>(`${BASE_URL}/api/test/onepost`, {
            Id: getState().test.data?.id,
            UserId: getState().auth.data?.userId,
            Items: getState().testAnswer.data,
         });
         if (!data) throw new Error("Result is null");
         dispatch(setWorkData(data));
         finishTestAction(dispatch);
      } catch (e: any) {
         throw new Error(e.message);
      }
   }
);

export const finishTest5 = createAsyncThunk<void, { type: "five" | "analiz" | "office" }, { state: RootState }>(
   "test/finish5",
   async (params, { getState, dispatch }) => {
      try {
         const api = {
            five: `${BASE_URL}/api/five/post`,
            analiz: `${BASE_URL_ANALIZ}/api/analiz/valid`,
            office: `${BASE_URL_OFFICE}/api/office/post`,
         };
         const { data } = await axios.post<ITestWork5>(api[params.type], {
            FiveId: getState().test.data5?.id,
            UserId: getState().auth.data?.userId,
            Items: getState().testAnswer.data5,
         });
         if (!data) {
            throw new Error("Result is null!");
         }
         dispatch(setWorkData5(data));
         finishTestAction(dispatch);
      } catch (e: any) {
         throw new Error(e.message);
      }
   }
);

export const finishTestNuska = createAsyncThunk<void, void, { state: RootState }>(
   "test/finishNuska",
   async (_, { getState, dispatch }) => {
      try {
         const auth = getState().auth.data;
         const testData = getState().test.dataNuska;
         const { data } = await axios.post(`${BASE_URL_NUSKA}/quiz/result`, {
            TestId: testData?.testId,
            UserId: auth?.userId,
            Fio: auth?.fio,
            NuskaId: testData?.nuskaId,
            Items: getState().testAnswer.dataNuska,
         });
         if (!data) {
            throw new Error("Result is null!");
         }
         dispatch(setWorkDataNuska(data));
         finishTestAction(dispatch);
      } catch (e: any) {
         throw new Error(e.message);
      }
   }
);
