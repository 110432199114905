import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { setFioWithModal } from "../../redux/slices/auth/auth.slice";
import { AppModal } from "./AppModal";
import { IModalOptions } from "./ModalOptions";

interface Props {
   startHandler: () => void;
}

export const FioModal = ({ startHandler }: Props) => {
   const [fio, setFio] = useState<string>("");
   const [error, setError] = useState<string | null>(null);

   const dispatch = useDispatch<AppDispatch>();

   const checkForm = () => {
      setError(null);

      if (fio.trim().length < 6) {
         return setError("Аты-жөніңізді толық жазыңыз!");
      }
      dispatch(setFioWithModal(fio.trim()));

      startHandler();
   };

   const options: IModalOptions = {
      btnId: "open_fio_modal",
      modalId: "fioModal",
      modalTitleId: "fioModalLabel",
      modalTitle: "Аты-жөні:",
      modalBody: (
         <>
            <div className="form-group row">
               <div className="col-sm-9">
                  <input className="form-control" type="text" value={fio} onChange={(e) => setFio(e.target.value)} />
                  {error && <p style={{ color: "red", fontSize: 14 }}>{error}</p>}
               </div>
            </div>
         </>
      ),
      btnCloseClick: () => {},
      btnFooter: (
         <>
            <button
               type="button"
               className="btn btn-secondary"
               onClick={() => checkForm()}
               disabled={fio.trim().length === 0}
            >
               OK
            </button>
            <button
               style={{ display: "none" }}
               id="close_fio"
               type="button"
               className="btn btn-secondary"
               data-dismiss="modal"
            >
               Close
            </button>
         </>
      ),
   };

   return <AppModal {...options} />;
};
