import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { TarifModal } from "../../../components/modals/TarifModal";
import { checkTarif } from "../../../redux/slices/auth/auth.slice";
import { getUserTarifDate } from "../../../utils/helpers/getUserTarifDate";
import { useHasTarif } from "../../../utils/hooks/useHasTarif";
import { select_pans } from "../../../utils/constants/select_pans";
import { PageTitle } from "../../../components/PageTitle";
import { useAppDispatch, useAppSelector } from "../../../redux/redux";
import { loadTest } from "../../../redux/slices/test/actions/test.load_actions";
import { StartTestButton } from "../../../components/buttons/StartTestButton";

export const Test1 = () => {
   const [selectedPanId, setSelectedPanId] = useState<number>(0);

   const authData = useAppSelector((state) => state.auth.data);
   const { isLoading: loading, error, confirm, data } = useAppSelector((state) => state.test);

   const dispatch = useAppDispatch();

   const hasTarif = useHasTarif();
   const history = useHistory();

   useEffect(() => {
      authData && dispatch(checkTarif(authData.userId!));
   }, [hasTarif]);

   useEffect(() => {
      if (confirm) {
         history.push(`/Auth/Test1/${data?.id}`);
         window.location.reload(); // для корректной работы MathJax
      }
   }, [confirm]);

   const startHandler = async () => {
      hasTarif
         ? dispatch(loadTest({ PanId: selectedPanId, UserId: authData?.userId! }))
         : document.getElementById("open_tarif_modal")?.click();
   };

   return (
      <>
         <TarifModal />
         <div className="container-fluid">
            <PageTitle>Пәндік тест</PageTitle>
            {authData && authData.tarif >= 2 && <p>Тариф бітетін уақыт: {getUserTarifDate(authData)}</p>}

            <div className="test-div">
               <p>Жеке пән бойынша тестті бастау</p>
               <select
                  className="form-control sele"
                  onChange={(e) => {
                     setSelectedPanId(parseInt(e.target.value));
                     const btn = document.getElementById("gobuttondiv");
                     parseInt(e.target.value) === 0
                        ? btn?.setAttribute("style", "display: none")
                        : btn?.setAttribute("style", "display: block");
                  }}
               >
                  <option value={0}>Пән</option>
                  {select_pans.map((pan) => (
                     <option key={pan.id.toString()} value={pan.id}>
                        {pan.panName}
                     </option>
                  ))}
               </select>
               <br />

               <StartTestButton {...{ loading, startHandler }} />
               {error && <div style={{ color: "red" }}>{error}</div>}
            </div>
         </div>
      </>
   );
};
