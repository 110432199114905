import React, { Dispatch, SetStateAction, useState } from "react";
import { PanList, getPanList2 } from "../utils/helpers/getSelectPans";
import { select_pans } from "../utils/constants/select_pans";

type Props = {
   setPan1: Dispatch<SetStateAction<number>>;
   setPan2: Dispatch<SetStateAction<number>>;
};

export const PansChanger = ({ setPan1, setPan2 }: Props) => {
   const [panList2, setPanList2] = useState<PanList[] | null>(null);

   const changePanHandler1 = (pan1_id: number) => {
      setPan1(pan1_id);
      setPanList2(getPanList2(pan1_id));
   };
   const changePanHandler2 = (pan2_value: number) => {
      setPan2(pan2_value);
      const btn = document.getElementById("gobuttondiv");
      if (pan2_value === 0) btn?.setAttribute("style", "display: none");
      else btn?.setAttribute("style", "display: block");
   };

   return (
      <>
         <div className="form-group">
            <select className="form-control sele" onChange={(e) => changePanHandler1(parseInt(e.target.value))}>
               <option value={0}>Пән 1</option>
               {select_pans.map((item) => {
                  if (item.id !== 4 && item.id !== 6 && item.id !== 7 && item.id !== 19) {
                     return (
                        <option key={item.id.toString()} value={item.id}>
                           {item.panName}
                        </option>
                     );
                  }
               })}
            </select>
         </div>
         <div className="form-group">
            <select className="form-control sele" onChange={(e) => changePanHandler2(parseInt(e.target.value))}>
               <option value={0}>Пән 2</option>
               {panList2 &&
                  panList2.map((item) => (
                     <option key={item.panName} value={item.id}>
                        {item.panName}
                     </option>
                  ))}
            </select>
         </div>
      </>
   );
};
