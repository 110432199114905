import React from "react";

type Props = {
   startHandler: () => void;
   loading: boolean | undefined;
};

export const StartTestButton = ({ loading, startHandler }: Props) => {
   return (
      <div className="form-group" style={{ display: "none" }} id="gobuttondiv">
         <button
            onClick={() =>
               navigator.onLine ? startHandler() : document.getElementById("open_connection_modal")?.click()
            }
            id="gobutton"
            className="btn widget-bg1"
            style={{ backgroundColor: "#4c1864", color: "white" }}
            disabled={loading}
         >
            {loading ? "Күте тұрыңыз..." : "Бастау"}
         </button>
         {loading && <i className="ml-2 fas fa-spinner fa-pulse"></i>}
      </div>
   );
};
