export const sidebarLinks = [
   {
      href: "/Auth/Account",
      icon: "ti-home",
      text: "Жеке Кабинет",
   },
   {
      href: "/Auth/Profil",
      icon: "ti-book",
      text: "Сабақтар",
   },
   {
      href: "/Auth/Test5",
      icon: "ti-check-box",
      text: "ҰБТ тапсыру",
   },
   {
      href: "/Auth/Test1",
      icon: "ti-pencil-alt",
      text: "Пәндік тест тапсыру",
   },
   {
      href: "/Auth/Search",
      icon: "ti-search",
      text: "Сұрақ-жауап",
   },
   // {
   //    href: "/Auth/TestTeacher",
   //    icon: "ti-stats-up",
   //    text: "Ұлттық біліктілік тестілеу",
   //    isTeacher: true,
   // },
   {
      href: "/Auth/TestAnaliz",
      icon: "ti-pencil-alt",
      text: "Тест - Анализ",
   },
   {
      href: "/Auth/NuskaList",
      icon: "ti-check-box",
      text: "Тест - Нұсқа",
      isNuska: true,
   },
];
